import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflarePerformance from '../../../containers/Services/Cloudflare/Performance';

const cloudflareData = {
  title: 'Application Performance with Cloudflare',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-performance.png',
    ogTitle: 'Application Performance Services | Tech Holding',
    ogDesc: 'Accelerate and Stabilize Your Digital Services with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/performance',
  },
  intro: {
    heading: 'Accelerate and Stabilize Your Digital Services',
    paragraphs: [
      'In an era where <b>speed and reliability</b> directly impact business growth, user retention, and digital experiences, application performance is more than just an IT concern it’s a competitive advantage.',
      'At <b>Tech Holding</b>, we partner with <b>Cloudflare</b> to deliver world-class performance optimization for web and mobile applications. From global content delivery to intelligent traffic routing, we help businesses eliminate latency, minimize downtime, and deliver seamless digital experiences anywhere in the world.',
    ],
  },
  sections: [
    {
      subheading: 'Protect Your Digital Frontiers',
      description:
        'Our advanced application security services harness the power of Cloudflare to offer:',
      columns: [
        {
          items: [
            {
              title: 'Content Delivery Network (CDN)',
              content:
                'Leverage <b>Cloudflare’s globally distributed edge network</b>, spanning 300+ cities, to cache and deliver static and dynamic content closer to users. Reduce latency, improve availability, and lower bandwidth costs.',
            },
            {
              title: 'DNS Resolution',
              content:
                'Cloudflare runs one of the <b>fastest and most reliable authoritative DNS services</b> in the world. With <b>built-in DDoS protection and smart routing</b>, your application’s responsiveness and uptime improve dramatically.',
            },
            {
              title: 'Argo Smart Routing',
              content:
                'Improve load times by routing traffic over Cloudflare’s optimized private backbone. Argo identifies the <b>fastest and most reliable paths</b> through the internet, reducing latency by an average of 30%.',
            },
            {
              title: 'Load Balancing',
              content:
                'Ensure high availability and resilience by distributing traffic across <b>multiple servers, regions, or cloud providers.</b> Cloudflare’s Load Balancer includes health checks, geographic routing, and automatic failover to keep your app always available.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Tiered Caching',
              content:
                'Optimize bandwidth consumption and cache hit rates using Cloudflare’s <b>Tiered Cache system</b>, which reduces origin load and improves response time by leveraging regional data centers.',
            },
            {
              title: 'Image & Asset Optimization',
              content:
                'Use <b>Cloudflare Images, Polish, and Mirage</b> to automatically compress, convert, and resize images based on user devices and browser types improving performance without compromising quality.',
            },
            {
              title: 'HTTP/3 and QUIC Support',
              content:
                'Support for next-generation protocols like HTTP/3 ensures faster, more reliable connections over modern networks, even in challenging mobile environments.',
            },
            {
              title: 'Performance Analytics',
              content:
                'Gain visibility into content delivery performance, cache hit ratios, and latency across geographies to fine-tune application delivery and user experiences.',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Performance Matters',
    columns: [
      {
        items: [
          {
            content: '<b>Every second of delay</b> can cost conversions and frustrate users.',
          },
          {
            content:
              'Global customers expect <b>consistent performance no matter where they are.</b>',
          },
        ],
      },
      {
        items: [
          {
            content:
              'Speed is a <b>ranking factor for search engines</b> and critical for SEO success.',
          },
        ],
      },
    ],
  },
  benefits: {
    heading: 'Business Benefits',
    columns: [
      {
        items: [
          {
            title: 'Faster Load Times',
            content: 'Deliver lightning-fast user experiences across devices and locations.',
          },
          {
            title: 'Lower Bandwidth & Infrastructure Costs',
            content: 'Reduce origin server traffic and associated costs.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Resilient & Scalable Infrastructure',
            content: 'Handle unexpected traffic spikes and maintain uptime.',
          },
          {
            title: 'Better SEO & Engagement',
            content: 'Improve bounce rates, page rank, and user satisfaction.',
          },
        ],
      },
    ],
  },

  performanceServices: {
    heading: 'Our Performance Services Include:',
    columns: [
      {
        items: [
          {
            content: 'CDN Strategy & Custom Configuration',
          },
          {
            content: 'DNS Setup & Acceleration',
          },
          {
            content: 'Load Balancing Architecture & Failover Setup',
          },
          {
            content: 'Argo Smart Routing Enablement',
          },
        ],
      },
      {
        items: [
          {
            content: 'Image Optimization with Cloudflare Polish & Mirage',
          },
          {
            content: 'Tiered Caching Strategy',
          },
          {
            content: 'Cloudflare Performance Analytics Integration',
          },
          {
            content: 'Ongoing Performance Monitoring & Tuning',
          },
        ],
      },
    ],
  },

  callToAction: {
    heading: 'Why Tech Holding?',
    subheading: 'Your applications are your business. Let us help you protect them.',
    description:
      'As a <b>Cloudflare Professional Services Partner</b>, Tech Holding brings deep expertise in optimizing digital platforms for speed, scale, and global reach. Whether you’re a fast-growing startup or an established enterprise, we design, deploy, and manage performance-focused solutions tailored to your business needs.',
  },

  accelerateText: {
    heading: 'Ready to Accelerate Your Applications?',
    description:
      'Let’s explore how Cloudflare + Tech Holding can make your applications faster, smarter, and more resilient.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const AppSecPage = ({ data }) => (
  <CloudflarePerformance details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

AppSecPage.propTypes = propTypes;

export default AppSecPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
