import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../../components/Layout';
import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import AppSecImg from '../../../../assets/services/CloudflarePerformance.png';
import CloudflareLogo from '../../../../assets/services/CloudflareLogo.png';
import s from '../Cloudflare.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    intro: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    callToAction: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    seoParams: PropTypes.shape({
      ogImagePath: PropTypes.string.isRequired,
      ogTitle: PropTypes.string.isRequired,
      ogDesc: PropTypes.string.isRequired,
      ogPath: PropTypes.string.isRequired,
    }).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            items: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
              }),
            ).isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    whyPartner: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(PropTypes.string).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string.isRequired,
              content: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    benefits: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(PropTypes.string).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string.isRequired,
              content: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    performanceServices: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    accelerateText: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const CloudflarePerformance = ({ details, companyQuote }) => {
  const { title, seoParams, sections, intro, callToAction, accelerateText } = details;
  const updatedSeo = getSeoConfig(seoParams);

  function parseBold(str) {
    const parts = str.split(/(<b>.*?<\/b>)/gi);

    return parts.map((part) => {
      if (part.toLowerCase().startsWith('<b>') && part.toLowerCase().endsWith('</b>')) {
        const content = part.slice(3, -4);
        return <strong key={`bold-${content}-${Date.now()}`}>{content}</strong>;
      }
      return <span key={`text-${part}-${Date.now()}`}>{part}</span>;
    });
  }

  return (
    <Layout
      currentPage="/services/cloudflare/performance"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
            <div className={s.image}>
              <img className={s.emoji} src={CloudflareLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <h3>{intro.heading}</h3>
        {intro.paragraphs.map((paragraph) => (
          <p>{parseBold(paragraph)}</p>
        ))}
        <h2>Our Cloudflare-Powered Application Performance Services</h2>
        <div className={s.image} data-aos="fade-up" data-aos-delay="400">
          <img className={s.emoji} src={AppSecImg} alt="" />
        </div>
        {sections.map((section) => (
          <div key={section.heading} className={s.block} data-aos="fade-up" data-aos-delay="400">
            <div className={s.flex}>
              {section.columns.map((column) => (
                <div key={column.id} className={s.column}>
                  <ul>
                    {column.items.map((item, itemIndex) => (
                      <li key={item.id || itemIndex}>
                        <strong>{item.title}</strong>
                        <strong>: </strong>
                        <span>{parseBold(item.content)}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{details.whyPartner.heading}</h2>
          <h4>{details.whyPartner.description}</h4>
          <div className={s.flex}>
            {details.whyPartner.columns.map((column) => (
              <div key={column.id} className={s.column}>
                <ul>
                  {column.items.map((item, itemIndex) => (
                    <li key={item.id || itemIndex}>{parseBold(item.content)}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{details.benefits.heading}</h2>
          <div className={s.flex}>
            {details.benefits.columns.map((column) => (
              <div key={column.id} className={s.column}>
                <ul>
                  {column.items.map((item, itemIndex) => (
                    <li key={item.id || itemIndex}>
                      <strong>{item.title}</strong>
                      <strong>: </strong>
                      <span>{parseBold(item.content)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{details.performanceServices.heading}</h2>
          <div className={s.flex}>
            {details.performanceServices.columns.map((column) => (
              <div key={column.id} className={s.column}>
                <ul>
                  {column.items.map((item, itemIndex) => (
                    <li key={item.id || itemIndex}>
                      <span>{parseBold(item.content)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{callToAction.heading}</h2>
          <p>{parseBold(callToAction.description)}</p>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{accelerateText.heading}</h2>
          <p>{parseBold(accelerateText.description)}</p>
        </div>
      </div>
    </Layout>
  );
};

CloudflarePerformance.propTypes = propTypes;
export default CloudflarePerformance;
